/* eslint-disable max-classes-per-file */
import RiskFlag from '@/domain/enums/RiskFlag';
import Item from '@/domain/Item';
import Location from '@/domain/Location';
import ItemSearchable from '@/dtos/filters/ItemSearchable';
import SearchFilterBase from '@/dtos/filters/SearchFilterBase';

export class InventorySearchFilterDTO extends SearchFilterBase {
    itemId?: number;

    locationIds: number[];

    riskFlags: RiskFlag[] = [];

    zeroQty?: boolean;

    constructor(init: InventorySearchFilter) {
        super(init.recordCount);
        if (init.item?.id) this.itemId = init.item.id;
        this.locationIds = init.locations.map((location) => location.id);

        if (init.riskFlagRed) {
            this.riskFlags.push(RiskFlag.Red);
        }
        if (init.riskFlagOrange) {
            this.riskFlags.push(RiskFlag.Orange);
        }
        if (init.riskFlagBlack) {
            this.riskFlags.push(RiskFlag.Black);
        }
        if (init.riskFlagNoFlag) {
            this.riskFlags.push(RiskFlag.NoFlag);
        }

        this.zeroQty = init.zeroQty;
    }
}

export type RiskFlagColor = keyof typeof RiskFlag;

export default class InventorySearchFilter extends SearchFilterBase implements ItemSearchable {
    item: Item | null = null;

    locations: Location[] = [];

    riskFlagRed: boolean = false;

    riskFlagOrange: boolean = false;

    riskFlagBlack: boolean = false;

    riskFlagNoFlag: boolean = false;

    zeroQty: boolean = false;

    constructor(init?: Partial<InventorySearchFilter>) {
        // TODO: Retrieve this value from a config file
        super();
        if (init) Object.assign(this, init);
    }

    get toDTO(): InventorySearchFilterDTO {
        return new InventorySearchFilterDTO(this);
    }
}
