import { CoreStore } from '@/store/CoreStore';

export default abstract class SearchFilterBase {
    protected constructor(recordCount?: number) {
        const { adminSettingsStore } = CoreStore.getInstance();
        this.recordCount = recordCount || adminSettingsStore.tableLoadCountDefault;
    }

    public recordCount;

    public hydrate(val: Partial<SearchFilterBase>) {
        Object.keys(val)
            .filter((x) => val.hasOwnProperty(x) && (val as any)[x] !== undefined && (val as any)[x] !== null)
            .forEach((x) => (this as any)[x] = (val as any)[x]);
    }
}
