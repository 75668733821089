import axios, { AxiosResponse } from 'axios';
import { DataAccessResponse } from '@/types/api/DataAccessResponse';
import { DataAccessResponseClass, DataListAccessResponseClass } from '@/services/api/dataAccessResponse';
import { API_SERVICE_URL } from '@/config/env';
import InventoryFlatDTO from '@/dtos/InventoryFlatDTO';
import InventoryGroupedDTO from '@/dtos/InventoryGroupedDTO';
import { InventorySearchFilterDTO } from '@/dtos/filters/InventorySearchFilter';
import { GetResultSetWithCountDTO } from '@/dtos/GetResultSetWithCountDTO';
import TotalQuantityInventoryAdjustmentDTO from '@/dtos/TotalQuantityInventoryAdjustmentDTO';
import AdjustConfirmedTransactionInventoryDTO from '@/dtos/AdjustConfirmedTransactionInventoryDTO';
import InventoryAdjustmentHistoryDTO from '@/dtos/InventoryAdjustmentHistoryDTO';
import ProcessPutAwayDTO from '@/dtos/ProcessPutAwayDTO';
import ItemTransformationDTO from '@/dtos/ItemTransformationDTO';

export default class InventoryApiService {
    public async createCombinedContainers(combinedContainerId: number, locationId: number, qty: number): Promise<DataAccessResponse<number>> {
        const response: AxiosResponse<DataAccessResponse<number>> = await axios({
            method: 'PUT',
            url: `${API_SERVICE_URL}/inventory/combined-container`,
            data: {
                combinedContainerId,
                locationId,
                quantity: qty,
            },
        });

        return new DataAccessResponseClass<number>(response).response;
    }

    public async deconstructCombinedContainers(combinedContainerId: number, locationId: number, qty: number): Promise<DataAccessResponse<number>> {
        const response: AxiosResponse<DataAccessResponse<number>> = await axios({
            method: 'PUT',
            url: `${API_SERVICE_URL}/inventory/deconstruct-container`,
            data: {
                combinedContainerId,
                locationId,
                quantity: qty,
            },
        });

        return new DataAccessResponseClass<number>(response).response;
    }

    public async getAllLocationInventory(locationId: number): Promise<DataAccessResponse<Array<InventoryGroupedDTO>>> {
        const response: AxiosResponse<DataAccessResponse<Array<InventoryGroupedDTO>>> = await axios({
            method: 'GET',
            url: `${API_SERVICE_URL}/inventory/location/${locationId}`,
        });

        return new DataListAccessResponseClass<InventoryGroupedDTO>(response, InventoryGroupedDTO).response;
    }

    public async getFilteredInventoryGroupedByCategory(dto: InventorySearchFilterDTO): Promise<DataAccessResponse<GetResultSetWithCountDTO<InventoryGroupedDTO>>> {
        const response: AxiosResponse<DataAccessResponse<GetResultSetWithCountDTO<InventoryGroupedDTO>>> = await axios({
            method: 'POST',
            url: `${API_SERVICE_URL}/inventory/filter/group-by/categories`,
            data: dto,
        });

        return new DataAccessResponseClass<GetResultSetWithCountDTO<InventoryGroupedDTO>>(response).response;
    }

    public async getFinishedInventoryForCombinedContainer(combinedContainerId: number, locationId: number): Promise<DataAccessResponse<Array<InventoryFlatDTO>>> {
        const response: AxiosResponse<DataAccessResponse<Array<InventoryFlatDTO>>> = await axios({
            method: 'GET',
            url: `${API_SERVICE_URL}/inventory/combined-container/finished-item/${combinedContainerId}/location/${locationId}`,
        });

        return new DataListAccessResponseClass<InventoryFlatDTO>(response, InventoryFlatDTO).response;
    }

    public async getTotalPutAwayInventoryAdjustments(transactionId: number): Promise<DataAccessResponse<Array<TotalQuantityInventoryAdjustmentDTO>>> {
        const response: AxiosResponse<DataAccessResponse<Array<TotalQuantityInventoryAdjustmentDTO>>> = await axios({
            method: 'GET',
            url: `${API_SERVICE_URL}/inventory/adjustments/put-away-totals/${transactionId}`,
        });

        return new DataListAccessResponseClass<TotalQuantityInventoryAdjustmentDTO>(response, TotalQuantityInventoryAdjustmentDTO).response;
    }

    public async getHasQtyOnHandByItemId(itemId: number): Promise<DataAccessResponse<boolean>> {
        const response: AxiosResponse<DataAccessResponse<boolean>> = await axios({
            method: 'get',
            url: `${API_SERVICE_URL}/inventory/item/${itemId}/has-qty-on-hand`,
        });

        return new DataAccessResponseClass<boolean>(response).response;
    }

    public async adjustConfirmedTransaction(adjustments: AdjustConfirmedTransactionInventoryDTO): Promise<DataAccessResponse<Array<TotalQuantityInventoryAdjustmentDTO>>> {
        const response: AxiosResponse<DataAccessResponse<Array<TotalQuantityInventoryAdjustmentDTO>>> = await axios({
            method: 'PUT',
            url: `${API_SERVICE_URL}/inventory/adjustments/adjust-confirmed`,
            data: adjustments,
        });

        return new DataListAccessResponseClass<TotalQuantityInventoryAdjustmentDTO>(response, TotalQuantityInventoryAdjustmentDTO).response;
    }

    public async getAllInventoryAdjustmentsByLocationItemAndCategory(
        locationId: number,
        itemId: number,
        categoryId: number,
        adjustForSort: boolean,
    ): Promise<DataAccessResponse<Array<InventoryAdjustmentHistoryDTO>>> {
        const response: AxiosResponse<DataAccessResponse<Array<InventoryAdjustmentHistoryDTO>>> = await axios({
            method: 'GET',
            url: `${API_SERVICE_URL}/inventory/adjustments/${locationId}/${itemId}/${categoryId}/${adjustForSort}`,
        });

        return new DataListAccessResponseClass<InventoryAdjustmentHistoryDTO>(response, InventoryAdjustmentHistoryDTO).response;
    }

    public async processPutAway(dto: ProcessPutAwayDTO): Promise<DataAccessResponse<number>> {
        const response: AxiosResponse<DataAccessResponse<number>> = await axios({
            method: 'PUT',
            url: `${API_SERVICE_URL}/inventory/process-put-away`,
            data: dto,
        });

        return new DataAccessResponseClass<number>(response).response;
    }

    public async transformItem(transformationDTO: ItemTransformationDTO): Promise<DataAccessResponse<number>> {
        const response: AxiosResponse<DataAccessResponse<number>> = await axios({
            method: 'POST',
            url: `${API_SERVICE_URL}/inventory/transform-item`,
            data: transformationDTO,
        });

        return new DataAccessResponseClass<number>(response).response;
    }
}
