export default class InventoryFlatDTO {
    constructor(init?: Partial<InventoryFlatDTO>) {
        Object.assign(this, init);
    }

    public id!: number;

    public locationId!: number;

    public itemId!: number;

    public quantity!: number;
}
