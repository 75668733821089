import { useNotification } from '@/composable/useNotifications';
import InventoryApiService from '@/services/api/InventoryApiService';
import InventoryFlatDTO from '@/dtos/InventoryFlatDTO';
import InventoryGroupedDTO from '../dtos/InventoryGroupedDTO';
import { GetResultSetWithCountDTO } from '@/dtos/GetResultSetWithCountDTO';
import InventorySearchFilter from '@/dtos/filters/InventorySearchFilter';
import Item from '@/domain/Item';
import Location from '@/domain/Location';
import TotalQuantityInventoryAdjustmentDTO from '@/dtos/TotalQuantityInventoryAdjustmentDTO';
import { getTitleCaseTranslation } from '@/services/TranslationService';
import AdjustConfirmedTransactionInventoryDTO from '@/dtos/AdjustConfirmedTransactionInventoryDTO';
import InventoryAdjustmentHistoryDTO from '@/dtos/InventoryAdjustmentHistoryDTO';
import ProcessPutAwayDTO from '@/dtos/ProcessPutAwayDTO';
import ItemTransformation from '@/domain/ItemTransformation';

export default class InventoryService {
    private inventoryApiService: InventoryApiService;

    private notification = useNotification();

    constructor() {
        this.inventoryApiService = new InventoryApiService();
    }

    public async createCombinedContainers(combinedContainer: Item, locationId: number, qty: number): Promise<boolean> {
        const { success } = await this.inventoryApiService.createCombinedContainers(combinedContainer.id, locationId, qty);

        if (success) {
            this.notification.showSuccess(`${getTitleCaseTranslation('core.common.assembled')} ${qty} ${combinedContainer.name}`);
        }

        return success;
    }

    public async deconstructCombinedContainers(combinedContainer: Item, locationId: number, qty: number): Promise<boolean> {
        const { success } = await this.inventoryApiService.deconstructCombinedContainers(combinedContainer.id, locationId, qty);

        if (success) {
            this.notification.showSuccess(`${getTitleCaseTranslation('core.common.disassembled')} ${qty} ${combinedContainer.name}`);
        }

        return success;
    }

    public async getFilteredInventoryGroupedByCategory(filter: InventorySearchFilter): Promise<GetResultSetWithCountDTO<InventoryGroupedDTO> | undefined> {
        const { success, data } = await this.inventoryApiService.getFilteredInventoryGroupedByCategory(filter.toDTO);
        return success ? data : undefined;
    }

    public async getItemInventoryGroupedByCategoryForSingleItem(itemId: number, locationId: number): Promise<InventoryGroupedDTO | undefined> {
        const filter = new InventorySearchFilter({
            item: new Item({ id: itemId }),
            locations: [new Location({ id: locationId })],
        });
        const responseBodyData = await this.getFilteredInventoryGroupedByCategory(filter);
        return responseBodyData ? new InventoryGroupedDTO(responseBodyData.resultSet[0]) : undefined;
    }

    public async getAllLocationInventory(locationId: number): Promise<{ inventory: Array<InventoryGroupedDTO>; success: boolean }> {
        const response = await this.inventoryApiService.getAllLocationInventory(locationId);

        return {
            inventory: response.data,
            success: response.success,
        };
    }

    public async getFinishedInventoryForCombinedContainer(combinedContainerId: number, locationId: number): Promise<InventoryFlatDTO[]> {
        const response = await this.inventoryApiService.getFinishedInventoryForCombinedContainer(combinedContainerId, locationId);

        return response.data;
    }

    public async getHasQtyOnHandByItemId(itemId: number): Promise<boolean> {
        const response = await this.inventoryApiService.getHasQtyOnHandByItemId(itemId);
        return response.success ? response.data : false;
    }

    public async getTotalPutAwayInventoryAdjustments(transactionId: number): Promise<Array<TotalQuantityInventoryAdjustmentDTO>> {
        const response = await this.inventoryApiService.getTotalPutAwayInventoryAdjustments(transactionId);

        if (response.success) {
            return response.data;
        }

        return [];
    }

    public async adjustConfirmedTransaction(adjustment: AdjustConfirmedTransactionInventoryDTO): Promise<Array<TotalQuantityInventoryAdjustmentDTO>> {
        const response = await this.inventoryApiService.adjustConfirmedTransaction(adjustment);

        if (response.success) {
            this.notification.showSuccess(getTitleCaseTranslation('core.common.updatedInventory'));
            return response.data;
        }

        return [];
    }

    public async getAllInventoryAdjustmentsByLocationItemAndCategory(
        locationId: number,
        itemId: number,
        categoryId: number,
        adjustForSort: boolean,
    ): Promise<{ inventory: Array<InventoryAdjustmentHistoryDTO>; success: boolean }> {
        const response = await this.inventoryApiService.getAllInventoryAdjustmentsByLocationItemAndCategory(locationId, itemId, categoryId, adjustForSort);

        return {
            inventory: response.data,
            success: response.success,
        };
    }

    public async processPutAway(dto: ProcessPutAwayDTO): Promise<number | undefined> {
        const response = await this.inventoryApiService.processPutAway(dto);

        if (response.success) {
            return response.data;
        }

        return undefined;
    }

    public async transformItem(itemTransformation: ItemTransformation): Promise<number | undefined> {
        const response = await this.inventoryApiService.transformItem(itemTransformation.toDTO);

        if (response.success) {
            return response.data;
        }

        return undefined;
    }
}
