import InventoryCategory from '@/domain/InventoryCategory';

export interface TotalQuantityInventoryAdjustment {
    inventoryCategory: InventoryCategory;
    itemId: number;
    quantity: number;
}

export default class TotalQuantityInventoryAdjustmentDTO implements TotalQuantityInventoryAdjustment {
    constructor(invAdj: TotalQuantityInventoryAdjustment) {
        this.inventoryCategory = new InventoryCategory(invAdj.inventoryCategory);
        this.itemId = invAdj.itemId;
        this.quantity = invAdj.quantity;
    }

    public inventoryCategory: InventoryCategory;

    public itemId: number;

    public quantity: number;
}
