import { normalizeDate } from '@/functions/date';

export default class InventoryAdjustmentHistoryDTO {
    public transactionId!: number;
    public fromLocationName!: string;
    public toLocationName!: string;
    public quantity!: number;
    public username!: string;
    public dateTime!: Date;
    public fromCategoryName!: string;
    public toCategoryName!: string;
    public fromSort!: boolean;

    constructor(init?: InventoryAdjustmentHistoryDTO) {
        Object.assign(this, init);
        if (init) {
            this.dateTime = normalizeDate(init.dateTime);
        }
    }

    public get transactionIdView(): string {
        return this.transactionId !== 0 ? this.transactionId.toString() : '';
    }
}
